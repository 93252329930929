import React from 'react'

import styles from './Footer.module.scss'

const Footer = () => {
  return (<footer>
      {/* <div className={`${styles.socialShare} ${styles.textCenter} ${styles.containerNarrow}`}>
        <div className={styles.shariff} data-services="[&quot;facebook&quot;, &quot;twitter&quot;, &quot;mail&quot;]">
          <ul className={`${styles.orientationHorizontal} ${styles.buttonStyleStandard} ${styles.shariffCol3}`}>
            <li className={styles.btnFacebook}>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcotsu.de%2F" data-rel="popup" rel="nofollow noopener noreferrer" target="_blank" title="Bei Facebook teilen" role="button" aria-label="Bei Facebook teilen">
                <span className={styles.faFacebookF} />
                <span className={styles.shareText}>teilen</span>
              </a>
            </li>
            <li className={styles.btnTwitter}>
              <a href="https://twitter.com/intent/tweet?text=&amp;url=https%3A%2F%2Fcotsu.de%2F" data-rel="popup" rel="nofollow noopener noreferrer" target="_blank" title="Bei Twitter teilen" role="button" aria-label="Bei Twitter teilen">
                <span className={styles.faTwitter} />
                <span className={styles.shareText}>twittern</span>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      <div className={`${styles.firstFooter} ${styles.footerNotes} ${styles.containerNarrow}`}>
        <h3>Kontakt und Rückmeldungen</h3>
        <p>Ich freue mich über Wünsche, Anregungen oder Hinweise auf Fehler! Schreib mir gerne eine kurze Nachricht:</p>
        <ul>
          <li>Mail an <a href="mailto:jakob@jakobsjapanisch.de">jakob@jakobsjapanisch.de</a></li>
        </ul>
        <h3>Spenden</h3>
        <p>Dein Beitrag hilft mir, weiterhin Inhalte zu erstellen und diesen Trainer zu verbessern.</p>
        <ul>
          <li><a href='https://www.paypal.me/jakobharder/'>Spenden mit PayPal</a></li>
          <li><a href='https://ko-fi.com/jakobsjapanisch/'>Spenden mit Ko-Fi</a></li>
        </ul>
        <h3>Letztes Update: März 2025</h3>
        <ul>
          <li>Bessere Lesbarkeit für Farbenblinde</li>
        </ul>
      </div>
      <div className={`${styles.footerNotes} ${styles.containerNarrow}`}>
        <p className={styles.textCenter}>
          <img className={styles.footerIcon} src={"/style/cotsucotsu-benkyo-shite.png"} alt=""/>
        </p>
        {false && <p>Quellen:</p>}
        {false && <ul>
          <li>Offizielle Joyo-Kanji: <a href="http://www.bunka.go.jp/kokugo_nihongo/sisaku/joho/joho/kijun/naikaku/pdf/joyokanjihyo_20101130.pdf" rel="noopener noreferrer">常用漢字表</a></li>
          <li>Schriftart für Radikale: <a href="https://github.com/kanjialive/kanji-data-media/tree/master/radicals-font" rel="noopener noreferrer">Japanese Radicals font</a></li>
        </ul>}
      </div>
      <div>
        <p className={styles.textCenter}>
          コツコツ. Gebaut von <a href="//jakobsjapanisch.de">Jakobs Japanisch</a>. <a href="//jakobsjapanisch.de/impressum" rel="nofollow">Impressum</a>
        </p>
      </div>
    </footer>)
}

export default Footer
